var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"um-component um-checkbox",class:{
    'is-checked': _vm.isChecked,
    'is-disabled': _vm.disabled,
    },style:({
    '--size': `${_vm.size}px`
})},[_c('span',{staticClass:"um-checkbox__input"},[_vm._m(0),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],staticClass:"um-checkbox__original",attrs:{"type":"checkbox","name":_vm.name,"disabled":_vm.disabled},domProps:{"value":_vm.label,"checked":Array.isArray(_vm.model)?_vm._i(_vm.model,_vm.label)>-1:(_vm.model)},on:{"change":function($event){var $$a=_vm.model,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.label,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.model=$$a.concat([$$v]))}else{$$i>-1&&(_vm.model=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.model=$$c}}}})]),_c('span',{staticClass:"um-checkbox__label"},[_vm._t("default"),(!_vm.$slots.default)?[_vm._v(_vm._s(_vm.label))]:_vm._e()],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"um-checkbox__inner"},[_c('i',{staticClass:"um-icon-select"})])
}]

export { render, staticRenderFns }