var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"um-component um-tag um-tag__main",class:`um-tag__bg__${_vm.bgType}`,style:({
    maxWidth: _vm.maxWidth!==0?`${_vm.maxWidth}px`:null,
    minWidth: _vm.minWidth!==0?`${_vm.minWidth}px`:null,
    fontSize: `${_vm.size*0.8}px`,
    '--lineheight': `${_vm.size*1.2}px`,
    '--mini': `${_vm.size/10}px`,
    '--miniNegative': `${_vm.size/10*-1}px`,
    '--radiusSize': `${_vm.size/3}px`,

})},[_vm._t("icon",function(){return [(_vm.iconType)?_c('img',{style:({
        height: `${_vm.size}px`,
        margin: `${_vm.size*0.2}px ${_vm.size/2}px ${_vm.size*0.2}px 0`,
        verticalAlign: `middle`
    }),attrs:{"src":require(`../assets/skills/i_skill_${_vm.iconType}.png`),"alt":"PNG"}}):_vm._e()]}),_c('span',{staticClass:"um-tag__text"},[_vm._t("default")],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }