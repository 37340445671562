var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"um-component um-card",style:({
    '--size': `${_vm.size}px`,
    '--width': _vm.width===-1?'100%':`${_vm.width}px`
})},[_c('div',{staticClass:"um-card__title",style:({
        backgroundColor: _vm.headerColor
    })},[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.$slots.moreinfo)?_c('span',{staticClass:"um-card__title__icon",class:_vm.showMoreInfo?'um-card-icon__common':'um-card-icon__reserve',on:{"click":_vm.showOrClose}},[_c('i',{staticClass:"um-icon-arrow-down-bold"})]):_vm._e()]),(_vm.$slots.default)?_c('div',{staticClass:"um-card__body"},[_vm._t("default")],2):_vm._e(),_c('div',{staticClass:"um-card__moreinfo",class:!_vm.showMoreInfo?'um-card__moreinfo__hidden':'',style:({ 
        maxHeight: _vm.maxHeight
         })},[_c('div',{ref:"moreinfo",staticClass:"um-card__moreinfo__inner"},[_vm._t("moreinfo")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }